import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AppProvider, Page } from '@shopify/polaris';
import {
  Provider as AppBridgeProvider,
  TitleBar,
} from "@shopify/app-bridge-react";
import { config } from "../config";
import { useSelector } from 'react-redux';
import { GetApiCall } from '../helpers/axios';
import { handlePageRefresh } from '../helpers/commonFunctions';
import CustomHeader from '../components/CustomHeader'
import CommonSingleProduct from '../components/CommonSingleProduct';
import Footer from "./Footer";


const SingleProduct = () => {;
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  const [productData, setProductData] = useState([]);
  const [totalpage,settotalPage] = useState(1);
  const [currpage, setCurrPage] = useState(1);
  const store_client_id = shop_data?.store_client_id;
  const header = { authentication: shopinfo.auth_token };
  console.log("SingleProduct working")
  const GetProductList = async (page,searchvalue) => {
    if(Object.keys(shop_data).length > 0){
      page = (page) ? page : 1;
      let searchval = (searchvalue) ? '&search_val='+searchvalue : ''
      let res = await GetApiCall('GET', `/single-product-list?store_client_id=${store_client_id}&page_no=${page}`+searchval, header);
      if (res?.data?.statusCode === 200 && res?.data?.status === 'success') {
        setProductData(res?.data?.data?.select_data);
        settotalPage(res?.data?.data?.total_page);
        setCurrPage(page);
      } else {
        setProductData([]);
      }
    }
  };

  useEffect(() => {
    // GetProductList();
    window.removeEventListener("beforeunload", handlePageRefresh);
  }, [shop_data]);

  return (
    <>
      {window.location.hostname === "localhost" ||
      window.location.href.indexOf("ngrok") > 1 ? (
        <CustomHeader
          Header="Single Product"
          ButtonName="Support/FAQ"
          Pricingbtn="Upgrade plan"
        />
      ) : (
        ""
      )}
      {window.location.hostname !== "localhost" ? (
        <>
          <AppProvider>
            <AppBridgeProvider config={config}>
              {/* <Page> */}
                
                <TitleBar title="Home" />
              <div style={{padding:"0px 20px"}}>
                <CommonSingleProduct
                  productData={productData}
                  totalPage={totalpage}
                  currentpage={currpage}
                  GetProductList={GetProductList}
                />
              </div>
                <Footer />
              {/* </Page> */}
            </AppBridgeProvider>
          </AppProvider>
        </>
      ) : (
        <Page title="Home">
          <CommonSingleProduct
            productData={productData}
            totalPage={totalpage}
            currentpage={currpage}
            GetProductList={GetProductList}
          />
          <Footer />
        </Page>
      )}
    </>
  );
};

export default SingleProduct;
