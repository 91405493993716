import React, { useState, useCallback, useEffect } from "react";
import {
  Page,
  LegacyCard,
  TextField,
  Icon,
  Button,
  IndexTable,
  useIndexResourceState,
  EmptySearchResult,
  Badge,
  Toast,
  Modal,
  Text,
  Pagination,
  AppProvider,
  ButtonGroup,
  Spinner
} from "@shopify/polaris";
import { SearchMinor, EditMajor, DeleteMinor } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { GetApiCall, ApiCall } from "../helpers/axios";
import { setTagsData } from "../redux/TagSlice";
import CustomHeader from "../components/CustomHeader";
import ApplicationStatus from "../components/ApplicationStatus";
import { htmlspecialchars_decode } from "../helpers/commonFunctions";
import Footer from "./Footer";
import {
  Provider as AppBridgeProvider,
  TitleBar,
} from "@shopify/app-bridge-react";
import { config } from "../config";

const Tags = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let app_status = shop_data.app_status;
  const [tagData, setTagData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const header = { authentication: shopinfo.auth_token };
  const [active, setActive] = useState(false);
  const [deleteId, setDeleteId] = useState([]);
  const [activeModel, setActiveModel] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [currentpage, setCurrentpage] = useState(1);
  const [tagloader, setTagLoader] = useState(false);
  const resourceName = {
    singular: "Tags",
    plural: "Tags",
  };
  const primaryAction = {
    content: "Add tag",
    onAction: () => navigate("/addTag"),
  };

  const GetTagList = async (page, search_val) => {
    setTagLoader(true);
    if (Object.keys(shop_data).length > 0) {
      page = page ? page : 1;
      let search =
        search_val && search_val.length >= 3
          ? `&search_val=${search_val}&page_no=1`
          : `&page_no=${page}`;
      let res = await GetApiCall(
        "GET",
        `/tag-list?store_client_id=${shop_data.store_client_id}${search}`,
        header
      );
      if (res.data.statusCode === 200 && res.data.status === "success") {
        setTagData(res.data.data.select_data);
        setFilteredResults(res.data.data.select_data);
        setCurrentpage(page);
        setTotalPage(res?.data?.data?.total_page);
        setTagLoader(false);
      } else if (
        res.data.status === "error" &&
        res.data.message === "Data not found!"
      ) {
        setTagLoader(false);
        setTagData([]);
      }
    }
  };
 

  useEffect(() => {
    if (searchValue.length >= 3 || searchValue.length === 0) {
      GetTagList(currentpage, searchValue);
    }
  }, [searchValue, currentpage, shop_data]);
  

  const toggleActiveToast = useCallback(
    () => setActiveToast((active) => !active),
    []
  );

  const toastMarkup = activeToast ? (
    <Toast
      content={toastMessage}
      onDismiss={toggleActiveToast}
      duration={1000}
    />
  ) : null;

  const handleChange = (id, flag = false) => {
    setDeleteId(id);
    if (flag) {
      handleDeleteAction(deleteId);
    } else {
      setActive(!active);
    }
  };

  const toggleActive = useCallback(
    () => setActiveModel((activeToast) => !activeToast),
    []
  );

  const handleDeleteAction = async (id) => {
    let res = await ApiCall("DELETE", "/delete-tag", { id: id }, header);
    if (res?.data?.statusCode === 200 && res.data.status === "success") {
      setToastMessage(res.data.message);
      setActive(!active);
      toggleActiveToast();
      GetTagList();
      toggleActive();
    }
  };

  const emptyStateMarkup = (
    <div className="pt-4">
      <EmptySearchResult
        title={"No tags yet"}
        description={"Try changing the filters or search term"}
        withIllustration
      />
    </div>
  );

  const promotedBulkActions = [
    {
      content: "Delete",
      onAction: () => {
        handleChange(selectedResources);
      },
      icon: DeleteMinor,
      destructive: true,
    },
  ];

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(searchValue.length >= 3 ? filteredResults : tagData);

  useEffect(() => {
    setDeleteId(selectedResources);
  }, [selectedResources]);

  const rowMarkup =
    filteredResults.map((data, index) => (
      <IndexTable.Row
        id={data.id}
        key={data.id}
        selected={selectedResources.includes(data.id)}
        position={index}
      >
        <IndexTable.Cell>
          {htmlspecialchars_decode(data.tag_name)}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div className="badge_status">
            {data.status === "0" ? (
              <Badge status="critical">Pre-order Disable</Badge>
            ) : (
              <Badge status="success">Pre-order Enabled</Badge>
            )}
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div className="d-flex justify-content-end">
            <ButtonGroup>
              <div className="edit_btn">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setTagsData(data));
                    navigate(`/tags/edit/${data.id}`);
                  }}
                >
                  <Icon source={EditMajor} color="base" />
                </Button>
              </div>
              <div className="edit_btn">
                <Button
                  onClick={() => {
                    handleChange(data.id);
                  }}
                >
                  <Icon source={DeleteMinor} destructive />
                </Button>
              </div>
            </ButtonGroup>
          </div>
        </IndexTable.Cell>
      </IndexTable.Row>
    ))

  const text = (
    <>
      <div className="card-border-setting">
        <LegacyCard title="Pre-order tags" sectioned>
          <TextField
            prefix={<Icon source={SearchMinor} color="base" />}
            value={searchValue}
            onChange={(value) => {
              setSearchValue(value);
            }}
            placeholder="Search tag"
            clearButton
            onClearButtonClick={() => setSearchValue("")}
            autoComplete="off"
          />
          <div className="pre-product-list" >
            {tagloader ? <div style={{ paddingTop: '20px' }}><Spinner
              accessibilityLabel="Spinner example"
              size="large"
            /> </div>: <>
              {rowMarkup && rowMarkup.length ? (
                <div className="pt-3">
                  <div className="tag_list">
                    <IndexTable
                      resourceName={resourceName}
                      itemCount={tagData.length}
                      selectedItemsCount={
                        allResourcesSelected ? "All" : selectedResources.length
                      }
                      promotedBulkActions={promotedBulkActions}
                      onSelectionChange={handleSelectionChange}
                      headings={[
                        { title: "Tag" },
                        { title: "Status" },
                        { title: "Action" },
                      ]}
                    >
                      {rowMarkup}
                    </IndexTable>
                  </div>
                  <div className="single-product-pagination">
                    {totalPage > 1 ? (
                      <Pagination
                        hasPrevious={
                          currentpage === 1 || currentpage > totalPage
                            ? false
                            : true
                        }
                        previousTooltip="Previous"
                        onPrevious={() => {
                          GetTagList(currentpage - 1, searchValue);
                        }}
                        hasNext={totalPage === currentpage ? false : true}
                        nextTooltip="Next"
                        onNext={() => {
                          GetTagList(currentpage + 1, searchValue);
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                emptyStateMarkup
              )}</>}
          </div>
        </LegacyCard>
      </div>
      <div>
        <Modal
          open={active}
          onClose={handleChange}
          title="Delete tag"
          primaryAction={{
            content: "Delete",
            destructive: true,
            onAction: () => {
              handleChange("", true);
            },
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: handleChange,
            },
          ]}
        >
          <Modal.Section>
            <Text>
              <p>
                Are you sure, you want to delete{" "}
                {selectedResources.length > 1 ? "selected tags" : "this tag"}?
              </p>
            </Text>
          </Modal.Section>
        </Modal>
        {toastMarkup}
      </div>
    </>
  );

  return (
    <>
      {window.location.hostname === "localhost" ||
        window.location.href.indexOf("ngrok") > 1 ? (
        <CustomHeader
          Header="Tags"
          ButtonName="Support/FAQ"
          Pricingbtn="Upgrade plan"
        />
      ) : (
        ""
      )}
      {window.location.hostname !== "localhost" ? (
        <>
          <AppProvider>
            <AppBridgeProvider config={config}>
              <Page>
                <TitleBar title="Tags" primaryAction={primaryAction} />
                {/* {app_status == "0" ? (
                  <ApplicationStatus header={header} shop_data={shop_data} />
                ) : null} */}
                {text}
                <Footer />
              </Page>
            </AppBridgeProvider>
          </AppProvider>
        </>
      ) : (
        <Page>
          <div className="header d-flex justify-content-between py-3">
            <h1 className="Polaris-Header-Title pb-3">Tags</h1>
            <Button onClick={() => navigate("/addTag")} primary>
              Add tag
            </Button>
          </div>
          {text}
        </Page>
      )}
    </>
  );
};

export default Tags;
