import React from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  let navigate = useNavigate();
  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-light">
        <div className="container-fluid">
          <span className="navbar-brand nav-bar" onClick={() => navigate("/")}>
            Pre Order
          </span>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <span
                  className="nav-link active nav-bar"
                  aria-current="page"
                  onClick={() => navigate("/")}
                >
                  Home
                </span>
              </li>
              <li className="nav-item">
                <span
                  className="nav-link nav-bar"
                  onClick={() => navigate("/single-product")}
                >
                  Single-Product
                </span>
              </li>
              <li className="nav-item">
                <span
                  className="nav-link nav-bar"
                  onClick={() => navigate("/tags")}
                >
                  Tag
                </span>
              </li>
              <li className="nav-item">
                <span
                  className="nav-link nav-bar"
                  onClick={() => navigate("/setting")}
                >
                  Setting
                </span>
              </li>
              <li className="nav-item">
                <span
                  className="nav-link nav-bar"
                  onClick={() => navigate("/all-products")}
                >
                  All product
                </span>
              </li>
              <li className="nav-item">
                <span
                  className="nav-link nav-bar"
                  onClick={() => navigate("/pricingplan")}
                >
                  All product
                </span>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
