import React from "react";
import { LegacyCard, TextField, Select, FormLayout } from "@shopify/polaris";

const CustomSettingButton = ({ formik, placementOptions }) => {
  return (
    <>
      <LegacyCard.Section title="Button">
        <FormLayout>
          <FormLayout.Group>
            <TextField
              name="text"
              label="Text on button"
              value={formik.values.text}
              placeholder="Preorder now"
              onChange={(value) => {
                formik.setFieldValue("text", value);
              }}
              autoComplete="off"
              onBlur={formik.handleBlur}
              error={
                formik.errors.text && formik.touched.text
                  ? formik.errors.text
                  : ""
              }
            />
            <TextField
              label="Pre-order message"
              value={formik.values.message}
              placeholder="Preorder message"
              onChange={(value) => {
                formik.setFieldValue("message", value);
              }}
              autoComplete="off"
            />
            <Select
              label="Pre-order message places"
              options={placementOptions}
              onChange={(value) => {
                formik.setFieldValue("message_placement", value);
              }}
              value={formik.values.message_placement}
            />
          </FormLayout.Group>
        </FormLayout>
      </LegacyCard.Section>
    </>
  );
};

export default CustomSettingButton;
