import React from 'react';
import {
    LegacyCard
  } from "@shopify/polaris";
const BannerInfo = (props) => {
  return (
    <LegacyCard >
        <div className="banner">
            <div className="banner-head-info">
                <div className="banner-heading">
                    <span className="banner-Icon-critical">
                    <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M10 14a.75.75 0 0 1-.75-.75v-3.5a.75.75 0 0 1 1.5 0v3.5a.75.75 0 0 1-.75.75Z"></path><path d="M9 7a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"></path><path fillRule="evenodd" d="M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-1.5 0a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0Z"></path></svg>
                    </span>
                    {props.title && <h2>{props.title}</h2>}
                </div>
            </div>
            <div className="banner-content">
                <span>
                    {props.description && <p className="custom-banner-description mb-2">
                    {props.description}
                    </p>}
                </span>
                {props.button && <button className="banner-btn">{props.button}</button>}
            </div>
        </div>
    </LegacyCard>
  )
}

export default BannerInfo;