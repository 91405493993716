import React from "react";
import { LegacyCard, TextField, Checkbox, Tooltip, Icon } from "@shopify/polaris";
import { InfoMinor } from '@shopify/polaris-icons';

const CustomStockWarning = ({ formik }) => {
  return (
    <>
      {/* <div className="card-border-setting"> */}
      <LegacyCard.Section title="A stock alert" sectioned>
        <div className="row">
          <div className="display-flex">
            <Checkbox
              label="Customers shouldn't be able to buy more than the amount of pre-order stock."
              checked={formik.values.prevent_customers}
              onChange={() =>
                formik.setFieldValue(
                  "prevent_customers",
                  !formik.values.prevent_customers
                )
              }
            />
            <div className="mt-1 ml-1">
              <Tooltip
                content={`Only works with Inventory Management Setting #1 and Specific Pre-order Stock. This function might not work with every theme. After you turn this feature on, we suggest that you check your shop's "add to cart" and "checkout" functions.`}
                dismissOnMouseOut borderRadius={1} width="wide"
              >
                <Icon source={InfoMinor} color="base" />
              </Tooltip>
            </div>
            {/* <br />
            <span className="stock-warning-color">
              Only works with Inventory Management Setting #1 and Specific
              Pre-order Stock. This function might not work with every theme.
              After you turn this feature on, we suggest that you check your
              shop's "add to cart" and "checkout" functions.
            </span> */}
          </div>
          <div className="row pt-2">
            <TextField
              label="Not enough items left to pre-order message"
              value={formik.values.order_remain_message}
              placeholder=""
              onChange={(value) => {
                formik.setFieldValue("order_remain_message", value);
              }}
              autoComplete="off"
            />
            <div className="stock-warning-color">
              <p>
                Note: We only have qty unit(s) left. number of units will be
                filled right away, and the rest will have to be pre-ordered.
              </p>
              <p className="font-12">
                Variables: qty. Your Shopify stock will replace the amount..
              </p>
            </div>
          </div>

          <div className="pt-2">
            <div className="display-flex">
              <Checkbox
                label="Tell people when a part of their order will be on pre-order."
                checked={formik.values.notify_customers}
                onChange={() =>
                  formik.setFieldValue(
                    "notify_customers",
                    !formik.values.notify_customers
                  )
                }
              />
              <div className="mt-1 ml-1">
                <Tooltip
                  content={`Only for Setting #2 of Inventory Management. This function might not work with every theme. After you turn this feature on, we suggest that you check your shop's "add to cart" and "checkout" functions`}
                  dismissOnMouseOut borderRadius={1} width="wide"
                >
                  <Icon source={InfoMinor} color="base" />
                </Tooltip>
              </div>
            </div>
            {/* <br />
            <span className="stock-warning-color">
              Only for Setting #2 of Inventory Management. This function might not work with every theme. After you turn this feature on, we suggest that you check your shop's "add to cart" and "checkout" functions
            </span> */}
          </div>
          <div className="row pt-2">
            <TextField
              label="Not enough items left to pre-order message"
              value={formik.values.order_message}
              placeholder=""
              onChange={(value) => {
                formik.setFieldValue("order_message", value);
              }}
              autoComplete="off"
            />
            <div className="stock-warning-color">
              <p>
                Note: We only have qty unit(s) left. number of units will be
                filled right away, and the rest will have to be pre-ordered.
              </p>
              <p className="font-12">
                Variables: qty. Your Shopify stock will replace the amount..
              </p>
            </div>
          </div>
        </div>
      </LegacyCard.Section>
      {/* </div> */}
    </>
  );
};

export default CustomStockWarning;
