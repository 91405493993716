import React from 'react';
import {
    Button,
    LegacyCard
  } from "@shopify/polaris";
const PricingPlanCard = (props) => {
  return (
    <div style={{maxWidth:'352px'}}>
        <LegacyCard>
            <div className='p-1 text-center' style={{ backgroundColor:'#303030', color: 'white', borderRadius:'12px 12px 0px 0px' }}>
              <p className='fw-semibold'>{props.tagTitle}</p>
            </div>
            <LegacyCard.Section>
              <div className='p-3 d-grid' style={{gap:'39px', borderRadius:'16px 10px 16px 10px'}}>
                <div>
                  <p className='fw-semibold'>{props.planType}</p>
                  <p>{props.trileTime}</p>
                </div>
                <p className='fw-semibold'><span  style={{fontSize:'40px'}}>{props.planPrice}</span>/{props.planDuration}</p>
                
                <div className=' d-grid gap-2'>
                  <p className='fw-semibold'>Standout features</p>
                  {props.feature1 && 
                    <div className='d-flex'>
                        <svg className='tik-color flex-shrink-0' viewBox="0 0 16 16" fill="none" aria-hidden="true" height="20" width="20" ><path d="M11.7785 4.98992C12.0566 5.25958 12.0754 5.71666 11.8205 6.01084L7.52781 10.9657C7.40194 11.111 7.22545 11.1955 7.03922 11.1998C6.853 11.2041 6.6732 11.1277 6.54149 10.9883L4.20002 8.51093C3.93333 8.22875 3.93333 7.77123 4.20002 7.48905C4.46672 7.20686 4.89913 7.20686 5.16583 7.48905L7.00294 9.43283L10.8136 5.03431C11.0685 4.74013 11.5005 4.72026 11.7785 4.98992Z" fill="currentColor"></path></svg>
                        <p>{props.feature1} </p>
                    </div>
                  }
                  {props.feature2 && 
                    <div className='d-flex'>
                        <svg className='tik-color flex-shrink-0' viewBox="0 0 16 16" fill="none" aria-hidden="true" height="20" width="20" ><path d="M11.7785 4.98992C12.0566 5.25958 12.0754 5.71666 11.8205 6.01084L7.52781 10.9657C7.40194 11.111 7.22545 11.1955 7.03922 11.1998C6.853 11.2041 6.6732 11.1277 6.54149 10.9883L4.20002 8.51093C3.93333 8.22875 3.93333 7.77123 4.20002 7.48905C4.46672 7.20686 4.89913 7.20686 5.16583 7.48905L7.00294 9.43283L10.8136 5.03431C11.0685 4.74013 11.5005 4.72026 11.7785 4.98992Z" fill="currentColor"></path></svg>
                        <p>{props.feature2} </p>
                    </div>
                  }
                  {props.feature3 && 
                    <div className='d-flex'>
                        <svg className='tik-color flex-shrink-0' viewBox="0 0 16 16" fill="none" aria-hidden="true" height="20" width="20" ><path d="M11.7785 4.98992C12.0566 5.25958 12.0754 5.71666 11.8205 6.01084L7.52781 10.9657C7.40194 11.111 7.22545 11.1955 7.03922 11.1998C6.853 11.2041 6.6732 11.1277 6.54149 10.9883L4.20002 8.51093C3.93333 8.22875 3.93333 7.77123 4.20002 7.48905C4.46672 7.20686 4.89913 7.20686 5.16583 7.48905L7.00294 9.43283L10.8136 5.03431C11.0685 4.74013 11.5005 4.72026 11.7785 4.98992Z" fill="currentColor"></path></svg>
                        <p>{props.feature3} </p>
                    </div>
                  }
                  {props.feature4 && 
                    <div className='d-flex'>
                        <svg className='tik-color flex-shrink-0' viewBox="0 0 16 16" fill="none" aria-hidden="true" height="20" width="20" ><path d="M11.7785 4.98992C12.0566 5.25958 12.0754 5.71666 11.8205 6.01084L7.52781 10.9657C7.40194 11.111 7.22545 11.1955 7.03922 11.1998C6.853 11.2041 6.6732 11.1277 6.54149 10.9883L4.20002 8.51093C3.93333 8.22875 3.93333 7.77123 4.20002 7.48905C4.46672 7.20686 4.89913 7.20686 5.16583 7.48905L7.00294 9.43283L10.8136 5.03431C11.0685 4.74013 11.5005 4.72026 11.7785 4.98992Z" fill="currentColor"></path></svg>
                        <p>{props.feature4} </p>
                    </div>
                  }
                  {props.feature5 && 
                    <div className='d-flex'>
                        <svg className='tik-color flex-shrink-0' viewBox="0 0 16 16" fill="none" aria-hidden="true" height="20" width="20" ><path d="M11.7785 4.98992C12.0566 5.25958 12.0754 5.71666 11.8205 6.01084L7.52781 10.9657C7.40194 11.111 7.22545 11.1955 7.03922 11.1998C6.853 11.2041 6.6732 11.1277 6.54149 10.9883L4.20002 8.51093C3.93333 8.22875 3.93333 7.77123 4.20002 7.48905C4.46672 7.20686 4.89913 7.20686 5.16583 7.48905L7.00294 9.43283L10.8136 5.03431C11.0685 4.74013 11.5005 4.72026 11.7785 4.98992Z" fill="currentColor"></path></svg>
                        <p>{props.feature5} </p>
                    </div>
                  }
                  {props.feature6 && 
                    <div className='d-flex'>
                        <svg className='tik-color flex-shrink-0' viewBox="0 0 16 16" fill="none" aria-hidden="true" height="20" width="20" ><path d="M11.7785 4.98992C12.0566 5.25958 12.0754 5.71666 11.8205 6.01084L7.52781 10.9657C7.40194 11.111 7.22545 11.1955 7.03922 11.1998C6.853 11.2041 6.6732 11.1277 6.54149 10.9883L4.20002 8.51093C3.93333 8.22875 3.93333 7.77123 4.20002 7.48905C4.46672 7.20686 4.89913 7.20686 5.16583 7.48905L7.00294 9.43283L10.8136 5.03431C11.0685 4.74013 11.5005 4.72026 11.7785 4.98992Z" fill="currentColor"></path></svg>
                        <p>{props.feature6} </p>
                    </div>
                  }
                  {props.feature7 && 
                    <div className='d-flex'>
                        <svg className='tik-color flex-shrink-0' viewBox="0 0 16 16" fill="none" aria-hidden="true" height="20" width="20" ><path d="M11.7785 4.98992C12.0566 5.25958 12.0754 5.71666 11.8205 6.01084L7.52781 10.9657C7.40194 11.111 7.22545 11.1955 7.03922 11.1998C6.853 11.2041 6.6732 11.1277 6.54149 10.9883L4.20002 8.51093C3.93333 8.22875 3.93333 7.77123 4.20002 7.48905C4.46672 7.20686 4.89913 7.20686 5.16583 7.48905L7.00294 9.43283L10.8136 5.03431C11.0685 4.74013 11.5005 4.72026 11.7785 4.98992Z" fill="currentColor"></path></svg>
                        <p>{props.feature7} </p>
                    </div>
                  }
                </div>
                {props.planButton && 
                    <div className='rounded-pill overflow-hidden'>
                    <Button primary fullWidth onClick={props.onButtonClick}>
                        {props.planButton}
                    </Button>
                    </div>
                }
              </div>
            </LegacyCard.Section>
        </LegacyCard>
    </div>
  )
}

export default PricingPlanCard;