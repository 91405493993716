import React from 'react';
import {
    LegacyCard
  } from "@shopify/polaris";
const BannerWarning = (props) => {
  return (
    <LegacyCard>
        <div className="banner">
            <div className="banner-head-warning">
                <div className="banner-heading">
                    <span className="banner-Icon-critical">
                    <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M10 6.75a.75.75 0 0 1 .75.75v3.5a.75.75 0 1 1-1.5 0v-3.5a.75.75 0 0 1 .75-.75Z"></path><path d="M11 13.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path><path fillRule="evenodd" d="M10 3.5c-1.045 0-1.784.702-2.152 1.447a449.26 449.26 0 0 1-2.005 3.847l-.028.052a403.426 403.426 0 0 0-2.008 3.856c-.372.752-.478 1.75.093 2.614.57.863 1.542 1.184 2.464 1.184h7.272c.922 0 1.895-.32 2.464-1.184.57-.864.465-1.862.093-2.614-.21-.424-1.113-2.147-2.004-3.847l-.032-.061a429.497 429.497 0 0 1-2.005-3.847c-.368-.745-1.107-1.447-2.152-1.447Zm-.808 2.112c.404-.816 1.212-.816 1.616 0 .202.409 1.112 2.145 2.022 3.88a418.904 418.904 0 0 1 2.018 3.875c.404.817 0 1.633-1.212 1.633h-7.272c-1.212 0-1.617-.816-1.212-1.633.202-.408 1.113-2.147 2.023-3.883a421.932 421.932 0 0 0 2.017-3.872Z"></path></svg>
                    </span>
                    {props.title && <h2>{props.title}</h2>}
                </div>
            </div>
            <div className="banner-content">
                <span>
                {props.description && <p className="custom-banner-description mb-2">
                    {props.description}
                    </p>}
                </span>
                {props.button && <button onClick={props.onButtonClick}  className="banner-btn">{props.button}</button>}
            </div>
        </div>
    </LegacyCard>
  )
}
export default BannerWarning;