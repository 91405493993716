function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export function handlePageRefresh(event) {
    const e = event || window.event
    /* Cancel the event */
    e.preventDefault()
    if (e) {
        e.returnValue = '' /* Legacy method for cross browser support */
    }
    return '' /* Legacy method for cross browser support */
}

export const encrypt = (link, id) => {
    let con_str = `${link}@$@${id}`;
    let ary = [2, 3, 4]
    let random = getRandomInt(3)
    let times = ary[random]
    for (let i = 0; i < times; i++) {
        con_str = window.btoa(con_str)
    }
    let final = window.btoa(`${con_str}@$@${times}`)
    return final
}

export const decrypt = (str) => {

    let firstDec = window.atob(str)
    let spl = firstDec.split("@$@")
    let dec = spl[0];
    for (let i = 0; i < parseInt(spl[1]); i++) {
        dec = window.atob(dec)
    }
    let final_id = dec.split("@$@")
    return final_id[1]
}

export const formatMoney = (t, e) => {
    t = t.toString().replace(/,/g, '');
    t = parseFloat(t).toFixed(2);
    var st_money = t.split(".");
    var st_count = '0';
    if (st_money[1] != undefined) {
        st_count = st_money[1].length;
    }
    function o(t, e) {
        return "undefined" == typeof t ? e : t
    }
    function i(t, e, i, n) {
        if (e = o(e, 2), i = o(i, ","), n = o(n, "."), isNaN(t) || null === t)
            return 0;
        if (st_count == 3) {
            t = (t / 1000).toFixed(e);
        } else if (st_count == 0 && e == 0) {
            t = t;
        } else if (st_count == 0) {
            t = (t).toFixed(e);
        } else {
            t = (t / 100).toFixed(e);
        }
        var a = t.split("."),
            r = a[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + i),
            s = a[1] ? n + a[1] : "";
        return r + s
    }
    if ("undefined" != typeof t && t) {
        "string" == typeof t && (t = t.replace(".", ""));
        var n = "",
            a = /\{\{\s*(\w+)\s*\}\}/;
        e = e || "${{amount}}";
        try {
            switch (e.match(a)[1]) {
                case "amount":
                    n = i(t, 2);
                    break;
                case "amount_no_decimals":
                    n = i(t, 0);
                    break;
                case "amount_with_comma_separator":
                    n = i(t, 2, ".", ",");
                    break;
                case "amount_with_space_separator":
                    n = i(t, 2, " ", ",");
                    break;
                case "amount_with_period_and_space_separator":
                    n = i(t, 2, " ", ".");
                    break;
                case "amount_no_decimals_with_comma_separator":
                    n = i(t, 0, ".", ",");
                    break;
                case "amount_no_decimals_with_space_separator":
                    n = i(t, 0, " ");
                    break;
                case "amount_with_apostrophe_separator":
                    n = i(t, 2, "'", ".");
                    break;
                default:
                    n = i(t, 2, " ", ".");
            }
            let final = e.replace(a, n);
            final = final.replace(/<[^>]+>/g, '')
            return final;
        } catch (r) { }
    }
    return t;
}

/**
 * Encode special characters into html code
  @param {} text 
 * @returns 
 */
export const htmlSpecialCharacterEncode = (text) => {
    let map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;',
        '’': '&#8217;',
        '‘': '&#8216;',
        '–': '&#8211;',
        '—': '&#8212;',
        '…': '&#8230;',
        '??': '&#8221;',
        '?': '?'
    };
    return text.replace(/[&<>"'’‘–—…??]/g, function (m) {
        return map[m];
    });
}

/**
 * Deocde special character encoded string into utf-8
  @param {} string 
  @param {} quoteStyle 
 * @returns 
 */
export const htmlspecialchars_decode = (string = '', quoteStyle = 2) => {
    string = string || '';
    string = string.toString()
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
    let OPTS = {
        'ENT_NOQUOTES': 0,
        'ENT_HTML_QUOTE_SINGLE': 1,
        'ENT_HTML_QUOTE_DOUBLE': 2,
        'ENT_COMPAT': 2,
        'ENT_QUOTES': 3,
        'ENT_IGNORE': 4
    }
    if (typeof quoteStyle !== 'number') {
        quoteStyle = OPTS[quoteStyle];
    }
    if (quoteStyle && OPTS.ENT_HTML_QUOTE_SINGLE) {
        string = string.replace(/&#039;/g, "'")
    }
    if (quoteStyle !== 0) {
        string = string.replace(/&quot;/g, '"')
    }
    string = string.replace(/&amp;/g, '&')
    return string
}