import React from "react";
import { Button, LegacyCard, Page, Text } from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
const PageNotFound = () => {
  let navigate = useNavigate();
  return (
    <>
      <Page>
        <LegacyCard sectioned>
          <div className="page-not-found-content text-center">
            <div>
              <Text variant="heading3xl" as="h2">
                Page Not Found{" "}
              </Text>
              <p className="pt-2">
                The requested URL /error was not found on this server.
              </p>
              <div className="pt-3">
                <Button onClick={() => navigate("/")}>Back to home</Button>
              </div>
            </div>
          </div>
        </LegacyCard>
      </Page>
    </>
  );
};

export default PageNotFound;
