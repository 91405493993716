import React, { useState, useCallback,useEffect } from "react";
import { LegacyCard, Button, Toast, Layout, Modal } from "@shopify/polaris";
import { ApiCall , GetApiCall, ShopifyCall } from "../helpers/axios";
import { setShopData } from "../redux/commonSlice";
import { useDispatch } from "react-redux";
import app_block_enable from "../assets/images/app_block_enable.png";
import app_block_disable from "../assets/images/app_block_disable.png";

const ApplicationStatus = ({ header, shop_data }) => {
  const dispatch = useDispatch();
  let store_client_id = shop_data?.shopInfo?.store_client_id;
  let app_status = shop_data?.shopInfo?.app_status;
  let store_name = shop_data?.shopInfo?.store_name;
  const [isEnable, setIsEnable] = useState(app_status == 0 ? true : false);
  const [activeEnable, setActiveEnable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [prevStatus, setPrevStatus] = useState(app_status == 0 ? true : false);
  const [url, seturl] = useState("");
  const handleModalChange = useCallback(() => setActive(!active), [active]);
  const handleClose = () => {
    handleModalChange();
  };
  const toggleActive = useCallback(
    () => setActiveEnable((activeEnable) => !activeEnable),
    []
  );

  const toastMarkup = activeEnable ? (
    <Toast
      duration={5000}
      content={isEnable ? "Disable Successfully" : "Enable Successfully"}
      onDismiss={toggleActive}
    />
  ) : null;
    
  useEffect(() => {
    // AppStatus();
  }, [store_client_id, shop_data]);
    
    const handleEnable = async () => {
      setLoading(true);
      let data = {
        store_client_id: store_client_id,
        shop_data: shop_data.shopInfo,
        onb_status: shop_data.shopInfo.onboarding_status,
      };

      let res = await ApiCall("PUT", "/app-enable", data, header);
      if (res?.data?.statusCode === 200) {
        let shop_Data = Object.assign({}, shop_data.shopInfo);
        shop_Data.app_status = isEnable ? "1" : "0";
        // window.open(res?.data?.data, "_blank");
        let shopInf = {
          "shopInfo": shop_Data,
          "auth_token": shop_data.auth_token,
        };
        dispatch(setShopData(shopInf));
        setIsEnable(!isEnable);
        toggleActive();
        setLoading(false);
        setActive(false);
        AppStatus();
      }
    };
    const handleDisable = async () => {
      setLoading(true);
      let data = {
        store_client_id: store_client_id,
        shop_data: shop_data,
        onb_status: shop_data.onboarding_status,
      };

      let res = await ApiCall("PUT", "/app-disable", data, header);
      if (res?.data?.statusCode === 200) {
        let shop_Data = Object.assign({}, shop_data.shopInfo);
        shop_Data.app_status = isEnable ? "1" : "0";
        // window.open(res?.data?.data, "_blank");
        let shopInf = {
          "shopInfo": shop_Data,
          "auth_token": shop_data.auth_token,
        };
        dispatch(setShopData(shopInf));
        setIsEnable(!isEnable);
        toggleActive();
        setLoading(false);
        setActive(false);
        AppStatus();
      }
    };

    const AppStatus = async () => {
      let result = await GetApiCall(
        "GET",
        `/app_block_status_change?store_client_id=${store_client_id}`,
        header
      );
      let block_status = result.data ? result.data.data : false;
      // let theme_url = result.data ? result.data.message : "";
      
      // seturl(theme_url);
      console.log(result);
      return block_status;
    };

    const handleStatusCheckAndAction = async () => {
      setLoading(true);
      // console.log(url);
      let url ="https://"+store_name+"/admin/themes/current/editor?context=apps&activateAppId=6db3227b-acff-4966-bcdd-53204d4488c4/pre-order-block";
      window.open(url, "_blank");
    
      const checkStatus = async (retries = 5, delay = 5000) => {
        for (let i = 0; i < retries; i++) {
          await new Promise(resolve => setTimeout(resolve, delay));
          const block_status = await AppStatus();
          if (block_status !== prevStatus) {
            if (block_status == true) {
              setPrevStatus(block_status);
              handleDisable();
            } else if (block_status == false) {
              setPrevStatus(block_status);
              handleEnable();
            }else{
              <Toast
                duration={5000}
                content={"Something Went Wrong"}
                onDismiss={toggleActive}
              />
            }
            setLoading(false);
            return;
          }
        }
        setLoading(false);
      };
    
      checkStatus();
    };
    


  return (
    <>
      <Layout.AnnotatedSection
        title="Application status"
        description="Enable or disable Pre-order on your store."
      >
        <LegacyCard sectioned>
          <div className="d-flex justify-content-between">
          {isEnable ? (
            <div className="custom-setting_page-right-header-title pt-2">
              App is now disabled.
            </div>
            ) : ( <div className="custom-setting_page-right-header-title pt-2">
                App is now enabled.
          </div>
            )}
            {isEnable ? (
              <div>
                <Button
                  onClick={() => { handleModalChange(active); }}
                  primary
                  loading={loading}
                >
                  Enable
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  onClick={() => { handleModalChange(active); }}
                  destructive
                  loading={loading}
                >
                  Disable
                </Button>
              </div>
            )}
          </div>
        </LegacyCard>
      </Layout.AnnotatedSection>
      {toastMarkup}
      <Modal
        open={active}
        onClose={handleClose}
        title={`Action required: ${isEnable ? 'Enable' : 'Disable'} Preorder on your store`}
        primaryAction={{
          content: 'Go to Theme Editor',
          onAction: () => handleStatusCheckAndAction(),
        }}
        secondaryActions={[
          {
            content: isEnable ? 'Enable later' : 'Disable later',
            onAction: handleClose,
          },
        ]}
        large={true}
      >
        <Modal.Section>
          <LegacyCard>
            <div className="pre_order_app_block">
              <img src={isEnable ? app_block_enable : app_block_disable} alt="Enable app embed" />
            </div>
          </LegacyCard>
        </Modal.Section>
      </Modal>
    </>
  );
};

export default ApplicationStatus;
