
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "@shopify/polaris/build/esm/styles.css";
import Router from "./router/Router";
import { setShopData } from "./redux/commonSlice";
import './assets/css/style.css'
import { generateTokenAPI, GetApiCall } from "./helpers/axios";
import { config, shop_name } from "./config";
import { NavigationMenu, Provider } from "@shopify/app-bridge-react";
import { Spinner } from '@shopify/polaris';
// import { useIntercom } from 'react-use-intercom';
import { Crisp } from "crisp-sdk-web";

function App() {
  let dispatch = useDispatch();
  let [onBoardingStatus, setOnBoardingStatus] = useState(0);
  const [shop, setShop] = useState();
  // Crisp.configure("bea43653-345b-4d1d-9484-4a4dfe2d34a1");  
  console.log("pre order")
  // const { boot, shutdown, hardShutdown, update } = useIntercom();
  let generateToken = async () => {
    if (shop_name) {
      let res = await generateTokenAPI("POST", "/generate-token", {
        shop: shop_name,
      });
      let shop_data = {
        "shopInfo": res.data.data.shop_data,
        "auth_token": res.data.data.token
      };
      let onboarding_status = shop_data?.shopInfo.onboarding_status
        ? shop_data?.shopInfo.onboarding_status
        : 0;
      setOnBoardingStatus(parseInt(onboarding_status));
      shop_data.block_status = true;
      if(window.location.pathname === '/setting'){
        
      let result = await GetApiCall("GET", `/app_block_status`, {
        authentication: shop_data.auth_token,
      });
      let block_status = result.data ? result.data.data : false;
      shop_data.block_status = block_status;
    }
      
    // update({
    //   email: shop_data?.shopInfo?.email ?? "NA",
    //   name: shop_data?.shopInfo?.shop_owner ?? "NA",
    //   shop_name: shop_data?.shopInfo?.shop_name ?? "NA",
    //   store_url: shop_data?.shopInfo?.store_name ?? "NA",
    //   shop_owner: shop_data?.shopInfo?.shop_owner ?? "NA",
    //   currency: shop_data?.shopInfo?.currency ?? "NA",
    //   country_code: shop_data?.shopInfo?.country_code ?? "NA",
    //   shop_plan: shop_data?.shopInfo?.shop_plan ?? "NA",
    //   timezone: shop_data?.shopInfo?.iana_timezone ?? "NA",
    // });
    // Crisp.user.setEmail(shop_data?.shopInfo?.email ?? "NA");
    // Crisp.user.setNickname(shop_data?.shopInfo?.shop_owner ?? "NA");
    // Crisp.session.setData({
    //   shop_name: shop_data?.shopInfo?.shop_name ?? "NA",
    //   store_url: shop_data?.shopInfo?.store_name ?? "NA",
    //   shop_owner: shop_data?.shopInfo?.shop_owner ?? "NA",
    //   currency: shop_data?.shopInfo?.currency ?? "NA",
    //   country_code: shop_data?.shopInfo?.country_code ?? "NA",
    //   shop_plan: shop_data?.shopInfo?.shop_plan ?? "NA",
    //   timezone: shop_data?.shopInfo?.iana_timezone ?? "NA",
    // });
    // Crisp.chat.show();
    // console.log(shop_data);
    Crisp.user.setEmail(shop_data?.shopInfo?.email ?? "NA");
    Crisp.user.setNickname(shop_data?.shopInfo?.shop_owner ?? "NA");
    Crisp.session.setData({
      shop_name: shop_data?.shopInfo?.shop_name ?? "NA",
      store_url: shop_data?.shopInfo?.store_name ?? "NA",
      shop_owner: shop_data?.shopInfo?.shop_owner ?? "NA",
      currency: shop_data?.shopInfo?.currency ?? "NA",
      country_code: shop_data?.shopInfo?.country_code ?? "NA",
      shop_plan: shop_data?.shopInfo?.shop_plan ?? "NA",
      timezone: shop_data?.shopInfo?.iana_timezone ?? "NA",
      phone: shop_data?.shopInfo?.phone ?? "NA",

    });
    Crisp.configure("bea43653-345b-4d1d-9484-4a4dfe2d34a1", {
      autoload: false,
      lockMaximized: false,
      lockFullview: false
    });
    Crisp.chat.open(); 
      setShop(shop_data);
      dispatch(setShopData(shop_data));
    } else {
      window.parent.location.href = "https://www.shopify.com/admin/apps";
    }
  };
  
  useEffect(() => {
    generateToken();
  }, []);

  if(shop){
    if (
      window.location.hostname === "localhost" ||
      window.location.href.indexOf("ngrok") > 1
    ) {
      return <Router shop = {shop.shopInfo} onBoardingStatus={onBoardingStatus} />;
    } else {
      return (
        <>
        
          {onBoardingStatus == 4 && (
           <Provider config={config}>
           <NavigationMenu
             navigationLinks={[
              //  {
              //    label: "Home",
              //    destination: "/",
              //  },
               
              
               {
                 label: "Settings",
                 destination: "/setting",
               },
               {
                label: "Tag",
                destination: "/tags",
              },
               {
                label: "Analytics",
                destination: "/analytics",
              },
               {
                label: "Plans",
                destination: "/pricingplan",
              },
             ]}
             matcher={(link, location) =>
               link.destination === location.pathname
             }
           />
         </Provider>
          )}
          <Router shop = {shop.shopInfo} onBoardingStatus={onBoardingStatus} />
        </>
      );
    }
  }else{
    return <><div className='page_spinner'><Spinner accessibilityLabel="Spinner example" size="large" /></div></>;
  }
}

export default App;
