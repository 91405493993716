import React from "react";
import {
  LegacyCard,
  TextField,
  Checkbox,
  Select,
  FormLayout,
} from "@shopify/polaris";
import preorder_img from "../../assets/images/preorder_img.jpg";

const CustomImageBadge = ({ title, formik, label, shape, text, strong, p }) => {
  const ribbonOptions = [
    { label: "Ribbon", value: "0" },
    { label: "Rectangle", value: "1" },
    { label: "Circle", value: "2" },
  ];

  return (
    <>
      <LegacyCard.Section title="Product image badge">
        <div className="row">
          <div className="col-lg-4 product-img-badge product-img-size">
            <img src={`https://appbackend.preorderbat.com/static/media/preorder_img.3eb8c6b5ed4874e9ad4a.jpg`} />
            {formik.values.badge_enabled ? (
              <>
                {formik.values.badge_shape == 0 ? (
                  <div className="preorder-badge-ribbon">
                    <span className="badge_shape">
                      {formik.values.badge_text}
                    </span>
                  </div>
                ) : formik.values.badge_shape == 1 ? (
                  <div className="preorder-badge-rectangle badge_shape">
                    <span>{formik.values.badge_text}</span>
                  </div>
                ) : (
                  <div className="preorder-badge-circle badge_shape">
                    <span>{formik.values.badge_text}</span>
                  </div>
                )}
              </>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-8">
            <div className="pb-2 mob-pt-1">
              <Checkbox
                name="badge_enabled"
                label="Badge enabled"
                checked={formik.values.badge_enabled}
                onChange={() =>
                  formik.setFieldValue(
                    "badge_enabled",
                    !formik.values.badge_enabled
                  )
                }
              />
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Select
                  label="Badge shape"
                  options={ribbonOptions}
                  onChange={(value) => {
                    formik.setFieldValue("badge_shape", value);
                  }}
                  value={formik.values.badge_shape}
                  disabled={!formik.values.badge_enabled}
                />
              </div>
              <div className="col-lg-6 mob-pt-1">
                <TextField
                  name="badge_text"
                  label="Badge text"
                  value={formik.values.badge_text}
                  placeholder="Badge text"
                  maxLength={15}
                  onChange={(value) => {
                    formik.setFieldValue("badge_text", value);
                  }}
                  autoComplete="off"
                  disabled={!formik.values.badge_enabled}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.badge_enabled && formik.touched.badge_enabled
                      ? formik.errors.badge_enabled
                      : ""
                  }
                />
              </div>
            </div>
            <div className="row pt-3">
              <p>
                <strong>Badge doesn't show up?</strong>
                For this feature to work with your theme, it may need to be set up in a certain way. Contact support if you need help.
              </p>
            </div>
          </div>
        </div>
      </LegacyCard.Section>
    </>
  );
};

export default CustomImageBadge;
