import React from "react";
import { LegacyCard, Checkbox } from "@shopify/polaris";

const CustomProductPage = ({ title, formik }) => {
  return (
    <>
      <LegacyCard.Section title={title} sectioned>
        <Checkbox
          label="Hide the ' buy now' buttons when the current version is on pre-order."
          checked={formik.values.hide_buy_button}
          onChange={() =>
            formik.setFieldValue(
              "hide_buy_button",
              !formik.values.hide_buy_button
            )
          }
        />
      </LegacyCard.Section>
    </>
  );
};

export default CustomProductPage;
