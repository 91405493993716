import React from "react";
import { useLocation } from "react-router-dom";
import { LegacyCard, TextField, FormLayout } from "@shopify/polaris";

const CustomCartLabel = ({ formik, label }) => {
  let location = useLocation();
  return (
    <>
      <LegacyCard.Section title="Cart label">
        <FormLayout>
          <FormLayout.Group>
            {location.pathname.includes("/settings") && (
              <TextField
                name="cart_label_key"
                label="Cart label key"
                value={formik.values.cart_label_key}
                placeholder=""
                onChange={(value) => {
                  formik.setFieldValue("cart_label_key", value);
                }}
                autoComplete="off"
                error={
                  formik.errors.cart_label_key && formik.touched.cart_label_key
                    ? formik.errors.cart_label_key
                    : ""
                }
              />
            )}
            <TextField
              name="cart_label_text"
              label={label ? label : "Cart label text"}
              value={formik.values.cart_label_text}
              placeholder=""
              onChange={(value) => {
                formik.setFieldValue("cart_label_text", value);
              }}
              autoComplete="off"
              error={
                formik.errors.cart_label_text && formik.touched.cart_label_text
                  ? formik.errors.cart_label_text
                  : ""
              }
            />
          </FormLayout.Group>
        </FormLayout>
      </LegacyCard.Section>
    </>
  );
};

export default CustomCartLabel;
