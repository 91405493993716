import React, { useEffect, useState } from "react";

import {
  LegacyCard,
  Icon,
  IndexTable,
  Page,
  TextField,
  AppProvider,
  Text,
  Button,
  EmptySearchResult,
  Spinner,
  Grid,
  Pagination,
  DataTable,
} from "@shopify/polaris";
import CustomHeader from "../components/CustomHeader";
import {
  SearchMinor,
  EditMajor,
  DeleteMinor,
  CashDollarMajor,
  ChecklistMajor,
  CheckoutMajor,
} from "@shopify/polaris-icons";
import { useSelector, useDispatch } from "react-redux";
import { GetApiCall } from "../helpers/axios";
import { useNavigate } from "react-router-dom";
import ApplicationStatus from "../components/ApplicationStatus";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import noimg from "../assets/images/noimg.jpg";
import Footer from "./Footer";
import {
  Provider as AppBridgeProvider,
  TitleBar,
} from "@shopify/app-bridge-react";
import { config } from "../config";

const Dashboard = () => {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  const header = { authentication: shopinfo.auth_token };
  const [productData, setProductData] = useState([]);
  const [totalProduct, setTotalProduct] = useState(0);
  const [totalOrder, setTotalOrder] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [bestSeller, setBestSeller] = useState([]);
  const [loader, setLoader] = useState(false);
  const [revenueLoader, setRevenueLoader] = useState(false);
  const [totalpage, settotalPage] = useState(1);
  const [currpage, setCurrPage] = useState(1);
  let app_status = shop_data.app_status;

  const preOrderSellerList = async () => {
    
    if (Object.keys(shop_data).length > 0) {
      setLoader(true);
      setRevenueLoader(true);
      let res = await GetApiCall(
        "GET",
        `/dashboardSellerget?store_client_id=${shop_data.store_client_id}`, header
      );
      if (res?.data?.statusCode === 200 && res?.data?.status === "success") {
        const total_rev =
          res?.data?.data?.total_revenue !== "" && res?.data?.data?.total_revenue !== null
            ? res?.data?.data?.total_revenue
            : 0;
        setBestSeller(res?.data?.data?.best_seller);
        setTotalProduct(res?.data?.data?.total_product);
        setTotalOrder(res?.data?.data?.total_order);
        setTotalRevenue(total_rev);
        setRevenueLoader(false);
        setLoader(false);
      } else {
        setBestSeller([]);
        setTotalProduct(0);
        setTotalOrder(0);
        setTotalRevenue(0);
        setRevenueLoader(false);
      }
    }
  };

  useEffect(() => {
    preOrderSellerList();
  }, [shop_data]);

  const preOrderProductList = async (page, searchValue) => {
    if (Object.keys(shop_data).length > 0) {
      setLoader(true);
      const rows = [];
      page = page ? page : 1;
      let searchval = searchValue ? "&search_val=" + searchValue : "";
      let res = await GetApiCall(
        "GET",
        `/getdashordProduct?store_client_id=${shop_data.store_client_id}&page_no=${page}${searchval}`, header
      );
      if (res?.data?.statusCode === 200 && res?.data?.status === "success") {
        setLoader(false);
        settotalPage(res?.data?.data?.total_page);
        setCurrPage(page);
        const productData = res?.data?.data?.products;
        if (productData.length > 0) {
          productData.map(
            ({ id, img, title, pre_order_type }) => {
              rows.push([
                <div className="d-flex align-items-center">
                  {/* <img width={"40px"} height={"40px"} src={img ? `${img}` : `${noimg}`} /> */}
                  <LazyLoadImage  
                    src={img ? img : noimg}
                    alt={title}
                    width="40"
                    height="40"
                    effect="blur"
                  />
                  <div className="px-3">{title}</div>                
                </div>,
             
                <div className="order_type">{pre_order_type}</div>,
               
              ]);
            }
          );
        } else {
          rows.push([<div className="no-data-found">No data found.</div>]);
        }
        setProductData(rows);
      } else {
        rows.push([<div className="no-data-found">No data found.</div>]);
        setProductData(rows);
        settotalPage(1);
        setCurrPage(1);
        setLoader(false);
      }
    }
  };

  useEffect(() => {

    if (searchValue.length >= 3) {
      preOrderProductList(currpage, searchValue);
    } else {
      preOrderProductList(currpage, "");
    }
  }, [searchValue, shop_data]);

  const resourceName = {
    singular: "customer",
    plural: "customers",
  };

  const sellerMarkup =
    bestSeller.length > 0 &&
    bestSeller.map(({ id, name, quantity, revenue }, index) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {name}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{quantity}</IndexTable.Cell>
        <IndexTable.Cell>{revenue}</IndexTable.Cell>
      </IndexTable.Row>
    ));

  const emptyStateMarkup = (
    <EmptySearchResult
      title={"No products yet"}
      description={"Try changing the filters or search term"}
      withIllustration
    />
  );

  return (
    <>
      {window.location.hostname === "localhost" ||
        window.location.href.indexOf("ngrok") > 1 ? (
        <CustomHeader
          Header="Dashboard"
          ButtonName="Support/FAQ"
          Pricingbtn="Upgrade plan"
        />
      ) : (
        ""
      )}
      {app_status == "0" ? (
        <Page>
          <div className="pre-setting-layout">
            <ApplicationStatus header={header} shop_data={shopinfo} />
          </div>
        </Page>
      ) : (
        ""
      )}
      {window.location.hostname !== "localhost" ? (
        <>
          <AppProvider>
            <AppBridgeProvider config={config}>
              <TitleBar title="Dashboard" />
              <Page>
                <Grid>
                  <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 4, xl: 4 }}>
                    <LegacyCard sectioned>
                      <div className="Polaris-LegacyStack Polaris-LegacyStack--alignmentBaseline">
                        <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                          <h2 className="Polaris-Text--root Polaris-Text--headingMd">Items from orders</h2>
                        </div>
                        <div className="Polaris-LegacyStack__Item">
                          <div className="Polaris-ButtonGroup">
                            <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                              <Icon source={CheckoutMajor} color="base"></Icon>
                            </div>
                          </div>
                        </div>
                      </div>
                      {revenueLoader ? (
                        <Spinner
                          accessibilityLabel="Spinner example"
                          size="small"
                        />
                      ) : (
                        <div className="pt-3"><b>{totalProduct}</b></div>
                      )}
                    </LegacyCard>
                  </Grid.Cell>
                  <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 4, xl: 4 }}>
                    <LegacyCard sectioned>
                      <div className="Polaris-LegacyStack Polaris-LegacyStack--alignmentBaseline">
                        <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                          <h2 className="Polaris-Text--root Polaris-Text--headingMd">Placed orders</h2>
                        </div>
                        <div className="Polaris-LegacyStack__Item">
                          <div className="Polaris-ButtonGroup">
                            <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                              <Icon source={ChecklistMajor} color="base"></Icon>
                            </div>
                          </div>
                        </div>
                      </div>
                      {revenueLoader ? (
                        <Spinner
                          accessibilityLabel="Spinner example"
                          size="small"
                        />
                      ) : (
                        <div className="pt-3">
                          <b>{totalOrder}</b>
                        </div>
                      )}
                    </LegacyCard>
                  </Grid.Cell>
                  <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 4, xl: 4 }}>
                    <LegacyCard sectioned>
                      <div className="Polaris-LegacyStack Polaris-LegacyStack--alignmentBaseline">
                        <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                          <h2 className="Polaris-Text--root Polaris-Text--headingMd">Generated revenue</h2>
                        </div>
                        <div className="Polaris-LegacyStack__Item">
                          <div className="Polaris-ButtonGroup">
                            <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                              <Icon source={CashDollarMajor} color="base"></Icon>
                            </div>
                          </div>
                        </div>
                      </div>
                      {revenueLoader ? (
                        <Spinner
                          accessibilityLabel="Spinner example"
                          size="small"
                        />
                      ) : (
                        <div className="pt-3">
                          <b>${Number.parseFloat(totalRevenue).toFixed(2)}</b>
                        </div>
                      )}
                    </LegacyCard>
                  </Grid.Cell>
                </Grid>
                <div className="pt-3">
                  <LegacyCard title="Purchased products list" sectioned>
                    <TextField
                      key={`search`}
                      prefix={<Icon source={SearchMinor} color="base" />}
                      value={searchValue}
                      onChange={(value) => {
                        setSearchValue(value);
                      }}
                      clearButton
                      onClearButtonClick={() => setSearchValue("")}
                      autoComplete="off"
                    />
                    <div className="pt-3 pre-product-list">
                      {loader ? (
                        <Spinner
                          accessibilityLabel="Spinner example"
                          size="large"
                        />
                      ) : (
                        <DataTable
                          columnContentTypes={["text", "text"]}
                          headings={[
                            <div className="preorder_pro_heading">
                              Product
                            </div>,
                            
                            <div className="preorder_pro_heading">
                              Pre-order setting type
                            </div>,
                            
                          ]}
                          rows={productData}
                        />
                      )}
                      <div className="single-product-pagination">
                        {totalpage > 1 ? (
                          <Pagination
                            hasPrevious={
                              currpage === 1 || currpage > totalpage
                                ? false
                                : true
                            }
                            previousTooltip="Previous"
                            onPrevious={() => {
                              preOrderProductList(currpage - 1, searchValue);
                            }}
                            hasNext={totalpage === currpage ? false : true}
                            nextTooltip="Next"
                            onNext={() => {
                              preOrderProductList(currpage + 1, searchValue);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </LegacyCard>
                  <LegacyCard title="Best selling products" sectioned>
                    <div className="best_seller">
                      <IndexTable
                        resourceName={resourceName}
                        itemCount={bestSeller.length}
                        emptyState={emptyStateMarkup}
                        selectable={false}
                        headings={[
                          { title: "Name" },
                          { title: "Quantity" },
                          { title: "Revenue" },
                        ]}
                      >
                        {sellerMarkup}
                      </IndexTable>
                    </div>
                  </LegacyCard>
                </div>
                <Footer />
              </Page>
            </AppBridgeProvider>
          </AppProvider>
        </>
      ) : (
        <>
          <Page>
            <Grid>
              <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 4, xl: 4 }}>
                <LegacyCard sectioned>
                  <div className="Polaris-LegacyStack Polaris-LegacyStack--alignmentBaseline">
                    <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                      <h2 className="Polaris-Text--root Polaris-Text--headingMd">Items from orders</h2>
                    </div>
                    <div className="Polaris-LegacyStack__Item">
                      <div className="Polaris-ButtonGroup">
                        <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                          <Icon source={CheckoutMajor} color="base"></Icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  {revenueLoader ? (
                    <Spinner
                      accessibilityLabel="Spinner example"
                      size="small"
                    />
                  ) : (
                    <div className="pt-3"><b>{totalProduct}</b></div>
                  )}
                </LegacyCard>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 4, xl: 4 }}>
                <LegacyCard sectioned>
                  <div className="Polaris-LegacyStack Polaris-LegacyStack--alignmentBaseline">
                    <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                      <h2 className="Polaris-Text--root Polaris-Text--headingMd">Placed orders</h2>
                    </div>
                    <div className="Polaris-LegacyStack__Item">
                      <div className="Polaris-ButtonGroup">
                        <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                          <Icon source={ChecklistMajor} color="base"></Icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  {revenueLoader ? (
                    <Spinner
                      accessibilityLabel="Spinner example"
                      size="small"
                    />
                  ) : (
                    <div className="pt-3">
                      <b>{totalOrder}</b>
                    </div>
                  )}
                </LegacyCard>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 4, xl: 4 }}>
                <LegacyCard sectioned>
                  <div className="Polaris-LegacyStack Polaris-LegacyStack--alignmentBaseline">
                    <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                      <h2 className="Polaris-Text--root Polaris-Text--headingMd">Generated revenue</h2>
                    </div>
                    <div className="Polaris-LegacyStack__Item">
                      <div className="Polaris-ButtonGroup">
                        <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                          <Icon source={CashDollarMajor} color="base"></Icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  {revenueLoader ? (
                    <Spinner
                      accessibilityLabel="Spinner example"
                      size="small"
                    />
                  ) : (
                    <div className="pt-3">
                      <b>${Number.parseFloat(totalRevenue).toFixed(2)}</b>
                    </div>
                  )}
                </LegacyCard>
              </Grid.Cell>
            </Grid>
            <div className="pt-3">
              <LegacyCard title="Pre-order products list" sectioned>
                <TextField
                  key={`search`}
                  prefix={<Icon source={SearchMinor} color="base" />}
                  value={searchValue}
                  onChange={(value) => {
                    setSearchValue(value);
                  }}
                  clearButton
                  onClearButtonClick={() => setSearchValue("")}
                  autoComplete="off"
                />
                <div className="pt-3 pre-product-list">
                  {loader ? (
                    <Spinner
                      accessibilityLabel="Spinner example"
                      size="large"
                    />
                  ) : (
                    <DataTable
                      columnContentTypes={["text", "text"]}
                      headings={[
                        <div className="preorder_pro_heading">
                          Product
                        </div>,
                        <div className="preorder_pro_heading">
                          Setting type
                        </div>,
                        
                      ]}
                      rows={productData}
                    />
                  )}
                  <div className="single-product-pagination">
                    {totalpage > 1 ? (
                      <Pagination
                        hasPrevious={
                          currpage === 1 || currpage > totalpage
                            ? false
                            : true
                        }
                        previousTooltip="Previous"
                        onPrevious={() => {
                          preOrderProductList(currpage - 1, searchValue);
                        }}
                        hasNext={totalpage === currpage ? false : true}
                        nextTooltip="Next"
                        onNext={() => {
                          preOrderProductList(currpage + 1, searchValue);
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </LegacyCard>
              <LegacyCard title="Best selling products" sectioned>
                <div className="best_seller">
                  <IndexTable
                    resourceName={resourceName}
                    itemCount={bestSeller.length}
                    emptyState={emptyStateMarkup}
                    selectable={false}
                    headings={[
                      { title: "Name" },
                      { title: "Quantity" },
                      { title: "Revenue" },
                    ]}
                  >
                    {sellerMarkup}
                  </IndexTable>
                </div>
              </LegacyCard>
            </div>
            <Footer />
          </Page>
        </>
      )}
    </>
  );
};

export default Dashboard;
