import styles from "./toggle-btn.css?url";

const ToggleButton = ({isChecked, handleCheckChange, index}) => {
    return(
        <label className="switch">
            <input
                type="checkbox"
                checked={isChecked}
                onChange={() => handleCheckChange(index, !isChecked)}
            />
            <span className="slider round"></span>
        </label>
    );
};

export default ToggleButton;
export const Link = () => {
    return[{rel: 'stylesheet', href: styles}]
};