import { createSlice } from "@reduxjs/toolkit"

export const TagSlice = createSlice({
    name: 'TagDetalis',
    initialState: { tag_data: [] },
    reducers: {
        setTagsData: (state, action) => {
            state.tag_data = action.payload
        }
    }
})

export const { setTagsData } = TagSlice.actions

export default TagSlice.reducer