import React from "react";
import { LegacyCard, FormLayout } from "@shopify/polaris";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const CustomOrderPeriod = ({ formik, p }) => {
  return (
    <>
      <LegacyCard.Section title="Pre-order period">
        <div className="pb-2">
          <p className="py-1">Timezone: Asia/Kolkata</p>
          <p className="pt-2">The pre-order time tells you when your pre-order settings will turn on and/or off. If you want your pre-order settings to take effect right away, leave the pre-order start date blank or choose a date in the past.</p>
        </div>
        <FormLayout>
          <FormLayout.Group>
            <div>
              <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular">
                Pre-order start date
              </span>
              <Datetime
                value={formik.values.order_start_date}
                onChange={(value) => {
                  formik.setFieldValue("order_start_date", value._d);
                }}
              />
            </div>
            <div>
              <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular">
                Pre-order end date
              </span>
              <Datetime
                value={formik.values.order_end_date}
                onChange={(value) => {
                  formik.setFieldValue("order_end_date", value._d);
                }}
              />
            </div>
          </FormLayout.Group>
        </FormLayout>
      </LegacyCard.Section>
    </>
  );
};

export default CustomOrderPeriod;
